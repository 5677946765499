<template>
  <div class=" ">
    <div class="background">
      <div class="d-flex flex-row justify-content-between container topMenu p-2">
        <ul class="nav nav-underline location-nav">
          <li class="nav-item location-item" v-for="location_id, key in magazyn" :key="key">
            <a :class="['nav-link location-link', (location_id.location_name == 'Rzeszów' ? 'active bg-light' : 'disabled')]" href="#">{{ location_id.location_name }}</a>
          </li>
          <li class="nav-item location-item">
            <a class="nav-link location-link disabled" href="#">Warszawa</a>
          </li>
          <li class="nav-item location-item">
            <a class="nav-link location-link disabled" href="#">Kraków</a>
          </li>
          <li class="nav-item location-item">
            <a class="nav-link location-link disabled" href="#">Katowice</a>
          </li>
          <li class="nav-item location-item">
            <a class="nav-link location-link disabled" href="#">Lublin</a>
          </li>
        </ul>
        <div class="offcanvas offcanvas-end" data-bs-scroll="true" data-bs-backdrop="false" tabindex="-1" id="list"
          aria-labelledby="listLabel">
          <div class="offcanvas-header">
            <h4 class="offcanvas-title">Legenda:</h4>
            <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Zamknij"></button>
          </div>
          <ul class="offcanvas-body" style="list-style: none;">
            <li id="status0" class="colorList">zamówienie utworzone</li>
            <li id="status2" class="colorList">gotowe do załadunku</li>
            <li id="status3" class="colorList">załadowano</li>
            <li id="status4" class="colorList">dostarczono</li>
          </ul>
          <div class="m-2">
            <button class="btn btn-outline-info w-100 m-1" data-bs-dismiss="offcanvas" @click="showInfo">Pokaż stan magazynów</button>
            <button class="btn btn-outline-danger w-100 m-1" @click="resetCalendar">Resetuj</button>
          </div>
        </div>
        <div>
          <button type="button" class="btn btn-outline-secondary p-2 refreshButton" @click="reloadCalendar">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-clockwise" viewBox="0 0 16 16">
              <path fill-rule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z"/>
              <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z"/>
            </svg>
          </button>
          <button class="btn btn-outline-info colorInfo" type="button" data-bs-toggle="offcanvas" data-bs-target="#list"
            aria-controls="list">
            Pokaż legendę
          </button>
        </div>
      </div>
      <div id="myModal" class="modal fade" ref="exampleModal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              <ul class="nav nav-underline">
                <li class="nav-item">
                  <a :class="'nav-link' + checkStatus(1) + checkActive(1)" href="#" aria-current="page"
                    @click="selectModal(1)">Zamówienie</a>
                </li>
                <li class="nav-item">
                  <a :class="'nav-link' + checkStatus(2) + checkActive(2)" href="#" @click="selectModal(2)">Towary</a>
                </li>
                <li class="nav-item">
                  <a :class="'nav-link' + checkStatus(3) + checkActive(3)" href="#" @click="selectModal(3)">Przyjęcie</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link disabled" href="#">Dostawa</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link disabled">Kopiowanie</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link disabled">Manager</a>
                </li>
              </ul>
            </div>
            <hr>
            <div v-if="selected == 1">
              <div class="modal-body">
                <form name="modalForm">
                  <div class="row form-group d-none">
                    <div class="col-3">
                      <label>Typ</label>
                    </div>
                    <div class="col-9">
                      <select v-model="order_type" class="form-select" name="type" :disabled="disableForms()">
                        <option value="order">Zamówienie do klienta</option>
                        <option value="zwrot">Zwrot do klienta</option>
                        <option value="mm">Przesunięcie między magazynami</option>
                      </select>
                    </div>
                  </div>
                  <div class="row form-group">
                    <div class="col-3">
                      <label>Kontrahent</label>
                    </div>
                    <div class="col-9">
                      <multiselect deselectLabel="Selected" :allowEmpty="false" v-model="kontrahentData"
                        :options="kontrahent" :custom-label="kontrahent.nazwa" label="nazwa" track-by="nazwa"
                        :optionHeight="20" :disabled="disableForms()"></multiselect>
                    </div>
                  </div>
                  <div class="row form-group">
                    <div class="col-3">
                      <label>Adres</label>
                    </div>
                    <div class="col-9">
                      <p v-if="kontrahentData">
                        {{ kontrahentData.ulica }}<br>
                        {{ kontrahentData.kodpocztowy }} {{ kontrahentData.miasto }}
                      </p>
                    </div>
                  </div>
                  <div class="row form-group">
                    <div class="col-3">

                    </div>
                    <div class="col">
                      <label>Dzień</label>
                    </div>
                    <div class="col">
                      <label>Godzina</label>
                    </div>
                  </div>
                  <div class="row form-group">
                    <div class="col-3">
                      <label>Data zamówienia</label>
                    </div>
                    <div class="col">
                      <date-picker value-type="YYYY-MM-DD" v-model="startDate" input-class="form-control" type="date"
                        :disabled="disableForms()"></date-picker>
                    </div>
                    <div class="col">
                      <date-picker :minute-step="15" value-type="HH:mm" format="HH:mm" v-model="startHour"
                        input-class="form-control" type="time" :disabled="disableForms()"></date-picker>
                    </div>
                  </div>
                </form>
              </div>
              <div class="modal-footer">
                <button v-if="this.eventData.status != null && !disableForms()" type="button" class="btn btn-danger"
                  @click="deleteEvent">Usuń</button>
                <button type="button" class="btn btn-secondary" @click="modalClose">Zamknij</button>
                <button v-if="!disableForms()" type="button" class="btn btn-primary" @click="modalConfirm">Dalej</button>
              </div>
            </div>
            <div v-if="selected == 2">
              <div class="modal-body">
                <form name="modalForm">
                  <div class="row form-group" v-if="eventData.status <= 2 || eventData.status == null">
                    <div class="col-3">
                      <label>Dodaj towary do zamówienia</label>
                    </div>
                    <div class="col-9">
                      <button type="button" class="btn btn-outline-info float-end" @click="addItem"
                        :disabled="disableForms()">Dodaj towar</button>
                    </div>
                  </div>
                  <div class="row form-group" v-for="index, key in itemsData" :key="key">
                    <div class="col-9">
                      <multiselect class="multi" deselectLabel="Selected" tagPlaceholder="" :allowEmpty="false"
                        v-model="index.product" :options="itemList" :custom-label="itemList.name" label="name"
                        track-by="name" :disabled="disableForms()"></multiselect>
                    </div>
                    <div class="col-2">
                      <input type="number" class="form-control" v-model="index.number" :disabled="disableForms()">
                    </div>
                    <div class="col-1 d-flex align-items-center">
                      <button type="button" class="btn btn-secondary multi p-2" @click="deleteItem(key)"
                        :disabled="disableForms()">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                          class="bi bi-trash" viewBox="0 0 16 16">
                          <path
                            d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                          <path fill-rule="evenodd"
                            d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                        </svg>
                      </button>
                    </div>
                  </div>
                  <hr>
                  <div class="row form-group">
                    <div class="col-3">
                      <label>Uwagi</label>
                    </div>
                    <div class="col-9">
                      <textarea v-model="uwagi" class="form-control textarea" :disabled="disableForms()"></textarea>
                    </div>
                  </div>
                </form>
              </div>
              <div class="modal-footer">
                <button v-if="this.eventData.status != null && !disableForms()" type="button" class="btn btn-danger"
                  @click="deleteEvent">Usuń</button>
                <button type="button" class="btn btn-secondary" @click="modalClose">Zamknij</button>
                <button v-if="!disableForms()" type="button" class="btn btn-primary" @click="modalConfirm">Zapisz
                  kartę</button>
              </div>
            </div>
            <div v-if="selected == 3">
              <div class="modal-body">
                <form name="modalForm">
                  <div class="row form-group" v-for="index, key in itemsDataOut" :key="key">
                    <div class="col-9">
                      <multiselect class="multi" deselectLabel="Selected" tagPlaceholder="" :allowEmpty="false"
                        v-model="index.product" :options="itemList" :custom-label="itemList.name" label="name"
                        track-by="name" :disabled="disableForms()"></multiselect>
                    </div>
                    <div class="col-2">
                      <input type="number" class="form-control" v-model="index.number" :disabled="disableForms()">
                    </div>
                    <div class="col-1 d-flex align-items-center">
                      <button type="button" class="btn btn-secondary multi p-2" @click="deleteItem(key)"
                        :disabled="disableForms()">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                          class="bi bi-trash" viewBox="0 0 16 16">
                          <path
                            d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                          <path fill-rule="evenodd"
                            d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                        </svg>
                      </button>
                    </div>
                  </div>
                  <hr>
                  <div class="row form-group">
                    <div class="col-3">
                      <label>Uwagi</label>
                    </div>
                    <div class="col-9">
                      <textarea v-model="uwagi" class="form-control textarea" :disabled="disableForms()"></textarea>
                    </div>
                  </div>
                </form>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" @click="modalClose">Zamknij</button>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div id="infoModal" class="modal fade" ref="infoModal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-xl">
          <div class="modal-content">
            <div class="modal-header container-fluid d-flex flex-row justify-content-between">
              <h2>Aktualny stan magazynów</h2>
              <div>
                <button type="button" class="btn btn-outline-secondary m-2 p-2" @click="refreshStorage">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-clockwise" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z"/>
                    <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z"/>
                  </svg>
                </button>
                <button type="button" class="btn btn-secondary" @click="infoModal.hide()">Zamknij</button>
              </div>
            </div>
            <hr>
            <div class="modal-body">
              <div class="d-flex flex-row justify-content-evenly infoModalBody">
                <div class="w-25 infoItem" v-for="location, key in storage" :key="key">
                  <h5>{{ location.location_name }}</h5>
                  <hr>
                  <ul class="list-group">
                    <li class="list-group-item d-flex flex-row justify-content-between" v-for="item, key in location.items" :key="key">
                      <div class="qrCode">{{ item.cylinder_qr_code }}</div>
                      <div>{{ item.cylinder_name }}</div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="calendar-window container-fluid bg-white overflow-hidden">
        <div class=''>
          <FullCalendar class=' ' :options='calendarOptions' ref="calendar" id="calendar">

          </FullCalendar>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import './assets/theme/app-calendar.css'
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import plLocale from '@fullcalendar/core/locales/pl'
//import { INITIAL_EVENTS, createEventId } from './event-utils'
import Multiselect from 'vue-multiselect'
import { Modal } from 'bootstrap'
import axios from 'axios'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import dayjs from 'dayjs'
import 'vue-multiselect/dist/vue-multiselect.min.css'
import 'vue2-datepicker/locale/pl.js'
import Swal from 'sweetalert2'
import 'sweetalert2/src/sweetalert2.scss'

export default {
  components: {
    FullCalendar,
    Multiselect,
    DatePicker
  },
  data: function () {
    return {
      currentGrid: 'timeGridWeek',
      firstTime: true,
      modal: null,
      infoModal: null,
      modalData: null,
      kontrahent: [],
      itemList: [],
      pojazd: [],
      firma: [],
      magazyn: [],
      storage: [],
      selected: 1,
      startDate: dayjs().format("YYYY-MM-DD"),
      startHour: dayjs().format("HH:mm"),
      endHour: dayjs().format("HH:mm"),
      day: dayjs().format("YYYY-MM-DD"),
      order_type: "order",
      uwagi: "",
      kontrahentData: {},
      itemsData: [],
      itemsDataOut: [],
      pojazdData: {
        id: "2",
        rej: "RST 123AB",
        model: "Magico Car",
        paliwo: "D",
        typ: "C",
        badania_tech: "2027-12-29",
        ubezpieczenie: "2027-12-26",
        vim: "dsfsd",
        uwagi: "Uwagi",
        euro: "19",
        delete: "0",
        pojemnosc: "3,0",
        podwozie: "skrzynia",
        firma: "2",
        data_rejestracji: "2018-12-24",
        image: null,
        gps_api: null,
        gps_hash: null,
        oauth_client_id: "pojazd_2LJkeAOaV",
        core_files_json: "[]"
      },
      firmaData: {
        id: "5",
        name: "Alter CT sp z o.o",
        naglowek: "",
        image: "",
        kodpocztowy: "37-450",
        miejscowosc: "Stalowa Wola",
        ulica: "Przemysłowa",
        nrbudynku: "11",
        wojewodztwo: "podkarpackie",
        nip: "8652562662",
        kontobankowe: "",
        delete: "1",
        stopka: null,
        nazwabanku: null,
        docprefix: null,
        docformatnumeracji: null,
        regon: "",
        default: "0",
        telefon: null,
        fax: null,
        email: null,
        email_json: null,
        position: null
      },
      eventData: {
        title: null,
        delete: "0",
        dzien: dayjs().format("YYYY-MM-DD"),
        dzien_print: "",
        kontrahenci_id: "",
        komentarz: "",
        start: "0",
        end: "",
        status: null,
        godzina_close: 0,
        firmy_id: "0",
        kierowcy_id: "0",
        adres_dostawy: "",
        adres_dostawy_on: "0",
        pojazdy_id: "2",
        numer: "",
        numer_data: "",
        oddzialy_id: "1",
        towaryuwagi: "",
        print_user: "56",
        order_type: "",
        cylinders: [],
        location_id: "0",
        email_send_date: "0000-00-00 00:00:00",
        items: []
      },
      calendarOptions: {
        plugins: [
          dayGridPlugin,
          timeGridPlugin,
          interactionPlugin // needed for dateClick
        ],
        headerToolbar: {
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,timeGridWeek,timeGridDay'
        },
        initialView: 'timeGridWeek',
        /*initialEvents: INITIAL_EVENTS, // alternatively, use the `events` setting to fetch from a feed*/
        editable: true,
        displayEventEnd: false,
        selectable: true,
        selectMirror: true,
        dayMaxEvents: true,
        weekends: true,
        select: this.handleDateSelect,
        eventDrop: this.handleEventDrop,
        eventClick: this.handleEventClick,
        eventsSet: this.handleEvents,
        datesSet: this.dateSet,
        events: this.events_get,
        locale: plLocale,
        allDaySlot: false,
        selectLongPressDelay: 1,
        slotMinTime: "7:00:00",
        slotMaxTime: "16:00:00",
        slotDuration: '00:15:00',
        slotLabelFormat: {
          hour: 'numeric',
          minute: '2-digit',
          omitZeroMinute: false,
          meridiem: 'short'
        },
        firstDay: 1,
        contentHeight: 'auto',
        eventBorderColor: '#bdbdbd'
        /* you can update a remote database when these fire:
        eventAdd:
        eventChange:
        eventRemove:
        */
      },
      currentEvents: []
    }
  },
  created: function () {
    document.title = "Kalendarz Magico"
  },
  methods: {
    handleWeekendsToggle() {
      this.calendarOptions.weekends = !this.calendarOptions.weekends // update a property
    },
    handleDateSelect(selectInfo) {
      if (this.currentGrid != 'dayGridMonth') {
        let date = new Date();
        if (selectInfo.start < date) {
          Swal.fire({
            title: "Nie można tworzyć zamówień w przeszłości",
            allowOutsideClick: false,
          })
        }
        else {
          this.selected = 1
          this.uwagi = ""
          this.startDate = this.returnDate(selectInfo.start)
          this.startHour = this.returnHour(selectInfo.start)
          let temp = dayjs(selectInfo.start).add(15, 'minutes').format('HH:mm')
          this.endHour = temp
          this.day = this.returnDate(selectInfo.start)
          this.resetTabs()
          this.modal.show()
        }
      }
    },
    handleEventClick(clickInfo) {
      this.reloadCalendar();
      this.modalData = clickInfo
      this.firstTime = false
      this.eventData.id = this.modalData.event._def.publicId
      this.kontrahentData = this.kontrahent.find(kontrahent => kontrahent.id == this.modalData.event.extendedProps.kontrahenci_id) //undefined kontrahentData do poprawy
      this.order_type = this.modalData.event.extendedProps.order_type
      this.startDate = this.returnDate(this.modalData.event.start)
      this.startHour = this.returnHour(this.modalData.event.start)
      let temp = dayjs(this.modalData.event.start).add(15, 'minutes').format('HH:mm')
      this.endHour = temp
      this.itemsData = []
      this.itemsDataOut = []
      this.modalData.event.extendedProps.items.forEach((items) => {
        if (items.direction == "out") {
          this.itemsData.push({
            number: items.quantity,
            product: this.itemList.find(item => item.id == items.product_id)
          })
        }
        else {
          this.itemsDataOut.push({
            number: items.quantity > 0 ? items.quantity: 1,
            product: this.itemList.find(item => item.id == items.product_id)
          })
        }
      })
      this.eventData.status = this.modalData.event.extendedProps.status
      this.uwagi = this.modalData.event.extendedProps.towaryuwagi
      this.resetTabs()
      this.modal.show()
    },
    handleEvents(events) {
      this.currentEvents = events
    },
    handleEventDrop(dropInfo) {
      this.modalData = dropInfo
      let currentdate = new Date()
      if(this.modalData.event.start > currentdate) {
        this.eventData.id = this.modalData.event._def.publicId
        this.kontrahentData = this.kontrahent.find(kontrahent => kontrahent.id == this.modalData.event.extendedProps.kontrahenci_id)
        this.order_type = this.modalData.event.extendedProps.order_type
        this.endHour = this.returnHour(this.modalData.event.end)
        this.eventData.start = dayjs(this.modalData.event.start).unix()
        this.eventData.end = dayjs(this.modalData.event.end).unix()
        this.eventData.dzien = this.startDate
        this.eventData.title = this.kontrahentData.nazwa
        this.eventData.kontrahenci_id = this.kontrahentData.id
        this.eventData.order_type = this.modalData.event.order_type
        this.itemsData = this.modalData.event.extendedProps.items
        this.eventData.status = this.modalData.event.extendedProps.status
        this.uwagi = this.modalData.event.extendedProps.towaryuwagi
        this.itemsData = []
        this.modalData.event.extendedProps.items.forEach((items) => {
          this.itemsData.push({
            number: items.quantity,
            product: this.itemList.find(item => item.id == items.product_id)
          })
        })
        this.itemsData.forEach((item) => this.eventData.items.push({
          delete: "0",
          title: item.product.name,
          quantityEmpty: "0",
          quantityFull: "0",
          direction: "out",
          product_id: item.product.id,
          quantity: item.number,
        }))

        axios.put("https://apps.magico3.co.pl/orderapp/v1/order/" + this.eventData.id, this.eventData).then(response => {
          this.eventData = response.data
          this.reloadCalendar();
        });
      }
      else{
        this.reloadCalendar();
        Swal.fire({
          title: "Nie można tworzyć zamówień w przeszłości",
          allowOutsideClick: false,
        })
      }
    },
    modalConfirm: function () {
      if (this.kontrahentData.nazwa == undefined) {
        Swal.fire("Wybierz kontrahenta")
        this.eventData.status = null
      }
      else {
        if (!this.eventData) this.fillOrder();
        if (!this.kontrahentData) {
          this.kontrahentData = {}
        }
        let checkItems = true
        if (this.itemsData.length != 0) {
          this.itemsData.forEach(items => {
            if (items.number < 1 || Object.keys(items.product).length === 0 && Object.getPrototypeOf(items.product) === Object.prototype && items.product) {
              checkItems = false;
            }
          })
        }
        if (checkItems) {
          this.eventData.status = 0
          this.eventData.start = dayjs(this.startDate + " " + this.startHour).unix()
          let temp = dayjs(this.startDate + " " + this.startHour).add(15, 'minutes').format('HH:mm')
          this.endHour = temp
          this.eventData.end = dayjs(this.startDate + " " + this.endHour).unix()
          this.eventData.dzien = this.startDate
          this.eventData.title = this.kontrahentData.nazwa
          this.eventData.kontrahenci_id = this.kontrahentData.id
          this.eventData.order_type = this.order_type
          this.eventData.towaryuwagi = this.uwagi
          this.eventData.firmy_id = this.firmaData.id
          this.eventData.pojazdy_id = this.pojazdData.id
          if (this.firstTime) {
            console.log(this.firstTime);
            this.firstTime = false;
            axios.post("https://apps.magico3.co.pl/orderapp/v1/order", this.eventData).then(response => {
              this.eventData = response.data.data
              this.reloadCalendar();
              this.successToast();
            });
            if (this.selected == 1) this.selected = 2
          }
          else {
            console.log(this.firstTime);
            this.eventData.items = [];
            this.itemsData.forEach((item) => this.eventData.items.push({
              delete: "0",
              title: item.product.name,
              quantityEmpty: "0",
              quantityFull: "0",
              direction: "out",
              product_id: item.product.id,
              quantity: item.number,
            }))
            console.log(this.eventData.items);
            console.log(this.itemsData)
            if (this.eventData.items.length == 0) this.eventData.status = 0
            else this.eventData.status = 2
            axios.put("https://apps.magico3.co.pl/orderapp/v1/order/" + this.eventData.id, this.eventData).then(response => {
              this.eventData = response.data
              this.reloadCalendar();
              this.successToast();
            });
            if(this.eventData.status == 2 && this.selected == 2) this.modal.hide()
            if (this.selected == 1) this.selected = 2
          }
        }
        else {
          Swal.fire("Uzupełnij pola towarów")
          this.eventData.status = 0;
        }
      }
    },
    reloadCalendar: function () {
      let calendarApi = this.$refs.calendar.getApi();
      calendarApi.refetchEvents();
    },
    modalClose: function () {
      this.firstTime = true;
      this.fillOrder();
      this.kontrahentData = {};
      this.itemsData = [];
      this.modal.hide();
    },
    selectModal: function (tab) {
      this.selected = tab;
    },
    checkActive: function (current) {
      if (current == this.selected) return " active";
      else return "";
    },
    resetTabs: function () {
      if (this.modalData) {
        if (this.modalData.event.extendedProps.status == 0) this.selected = 2
      }
      else this.selected = 1
    },
    getOptions: function () {
      axios.get("https://apps.magico3.co.pl/orderapp/v1/kontrahent",
        {
          withCredentials: true
        })
        .then((response) => {
          this.kontrahent = response.data.data;
        });
      axios.get("https://apps.magico3.co.pl/orderapp/v1/product",
        {
          withCredentials: true
        })
        .then((response) => {
          this.itemList = response.data.data;
        });
      axios.get("https://apps.magico3.co.pl/orderapp/v1/pojazd",
        {
          withCredentials: true
        })
        .then((response) => {
          this.pojazd = response.data.data;
        });
      axios.get("https://apps.magico3.co.pl/orderapp/v1/firma",
        {
          withCredentials: true
        })
        .then((response) => {
          this.firma = response.data.data;
        });
      axios.get("https://apps.magico3.co.pl/orderapp/v1/location?type=magazyn",
        {
          withCredentials: true
        })
        .then((response) => {
          this.magazyn = response.data.data;
        });
      axios.get("https://apps.magico3.co.pl/orderapp/v1/storage",
        {
          withCredentials: true
        })
        .then((response) => {
          this.storage = response.data;
        });
    },
    returnDate: function (getDate) {
      var date = new Date(getDate);
      var D = "";
      D += date.getFullYear() + "-";
      if (date.getMonth() <= 10) D += "0";
      D += (date.getMonth() + 1) + "-";
      if (date.getDate() <= 10) D += "0";
      D += (date.getDate());
      return D;
    },
    returnHour: function (getDate) {
      var date = new Date(getDate);
      var H = "";
      if (date.getHours() < 10) H += "0";
      H += date.getHours() + ":";
      if (date.getMinutes() < 10) H += "0";
      H += date.getMinutes();
      return H;
    },
    refreshStorage: function(){
      axios.get("https://apps.magico3.co.pl/orderapp/v1/storage",
        {
          withCredentials: true
        })
        .then((response) => {
          this.storage = response.data;
          this.reloadCalendar();
        });
    },
    fillOrder: function () {
      let order = {
        title: null,
        delete: "0",
        dzien: dayjs().format("YYYY-MM-DD"),
        dzien_print: "",
        kontrahenci_id: "",
        komentarz: "",
        start: "0",
        end: "",
        status: null,
        godzina_close: 0,
        firmy_id: "0",
        kierowcy_id: "0",
        adres_dostawy: "",
        adres_dostawy_on: "0",
        pojazdy_id: "2",
        numer: "",
        numer_data: "",
        oddzialy_id: "1",
        towaryuwagi: "",
        print_user: "56",
        order_type: "",
        cylinders: [],
        location_id: "0",
        email_send_date: "0000-00-00 00:00:00",
        items: []
      }
      this.eventData = order
    },
    addItem: function () {
      this.itemsData.push(
        {
          product: {},
          number: 1
        }
      )
    },
    deleteItem: function (index) {
      this.itemsData.splice(index, 1)
    },
    checkStatus: function (current) {
      switch (current) {
        case 2:
          if (this.eventData.status == null) return " disabled";
          break;
        case 3:
          if (this.eventData.status <= 2 || this.eventData.status == null) return " disabled"
          break;
      }
      return ""
    },
    disableForms: function () {
      if (this.eventData.status >= 3) return true;
      return false;
    },
    successToast: async function () {
      const Toast = Swal.mixin({
        toast: true,
        position: 'top-right',
        iconColor: 'white',
        customClass: {
          popup: 'colored-toast'
        },
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true
      });
      await Toast.fire({
        icon: 'success',
        title: 'Sukces'
      })
    },
    deleteEvent: function () {
      Swal.fire({
        title: 'Jesteś pewien?',
        text: "To zamówienie zostanie usunięte!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Tak, usuń',
        cancelButtonText: "Anuluj"
      }).then((confirm) => {
        if (confirm.isConfirmed) {
          axios.delete("https://apps.magico3.co.pl/orderapp/v1/order/" + this.eventData.id).then((response) => {
            console.log("delete: " + response.data.status)
            this.reloadCalendar();
            this.modal.hide();
          })
        }
      });
    },
    resetCalendar: function () {
      let vm = this;
      Swal.fire({
        title: 'Jesteś pewien?',
        text: "Kalendarz zostanie zresetowany!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Tak, resetuj',
        cancelButtonText: "Anuluj"
      }).then((confirm) => {
        if (confirm.isConfirmed) {
          Swal.fire({
            title: "Czyszczenie...",
            html: '<img src="clean.gif" class="cleanGif">',//<div class="spinner-border m-5" role="status"></div>
            allowOutsideClick: false,
            showConfirmButton: false,
            onBeforeOpen: () => {
              Swal.showLoading()
            },
          });
          axios.get("https://apps.magico3.co.pl/orderapp/v1/reset").then(function () {
            vm.reloadCalendar();
            vm.modal.hide();
            Swal.close();
            Swal.fire("Kalendarz został zresetowany", {
              icon: "success",
            })
          })
        }
        this.reloadCalendar();
      });
    },
    events_get(info, successCallback) {
      var vm = this;

      axios.get("https://apps.magico3.co.pl/orderapp/v1/order").then(response => {
        response.data.data.forEach(item => {

          if (this.currentGrid == 'dayGridMonth') {
            item.allDay = true;
            item.editable = false;
          }
          else if (item.status >= 3) {
            item.allDay = false;
            item.editable = false;
          }
          else item.editable = true

          item.backgroundColor = "#ffe0db";//'#FC675B'; zamówienie utworzone
          item.textColor = '#fc563a';

          if (item.status == 1) {
            item.backgroundColor = '#e6fbe1';//'#00B59C'; 
            item.textColor = '#71e363';

          }
          if (item.status == 2) {
            item.backgroundColor = '#d5f4fc';//'rgb(11,8,207)';//' #3BAEE7'; gotowe do załadunku
            item.textColor = '#07b8de';

            // item.editable=false;
          }
          if (item.status == 3) {
            item.backgroundColor = '#fad0a5';//'#EB7015'; załadowano
            item.textColor = '#f26f2e';

          }
          if (item.status == 4) {
            item.backgroundColor = '#fff3d8';//'#C9CFD2'; dostarczono
            item.textColor = '#f59920';
          }
          item.start = dayjs.unix(item.start).format('YYYY-MM-DD HH:mm')// dayjs(item.start)
          item.end = dayjs.unix(item.end).format('YYYY-MM-DD HH:mm')// dayjs(item.start)
        });
        vm.events = response.data.data;
        successCallback(vm.events);
      });
    },
    dateSet: function (dateInfo) {
      this.currentGrid = dateInfo.view.type;
      this.reloadCalendar();
    },
    showInfo: function () {
      this.refreshStorage();
      this.infoModal.show();
    }
  },
  mounted() {
    this.modal = new Modal(this.$refs.exampleModal);
    this.infoModal = new Modal(this.$refs.infoModal);
    this.getOptions();
    document.getElementById("myModal").addEventListener('hidden.bs.modal', this.modalClose);
    document.getElementById("infoModal").addEventListener('hidden.bs.modal', this.modalClose);
  }
}
</script>

<style lang='css'>
h2 {
  margin: 0;
  font-size: 16px;
}

ul {
  margin: 0;
  padding: 0 0 0 1.5em;
}

li {
  margin: 1.5em 0;
  padding: 0;
}

b {
  /* used for event dates/times */
  margin-right: 3px;
}



.fc {
  /* the calendar root */
  /*margin: 0;*/
  max-height: 100%;
}

form .form-control,
form label,
form p {
  margin: 10px;
}

.multiselect__element {
  margin: 0;
}

.multi {
  margin: 10px;
}

.nav-item {
  margin: 0;
}

.modal-header {
  margin-bottom: 0;
}

.background {
  background-color: #f5f5f9;
  max-height: 100%;
}

.calendar-window {
  max-width: 1400px;
  padding: 30px;
  border-radius: 15px;
}

.colored-toast.swal2-icon-success {
  background-color: #a5dc86 !important;
}

.swal2-container {
  z-index: 20000 !important;
}

.modal {
  z-index: 900;
}

.fc-button {
  background-color: white !important;
  border-color: white !important;
}

.fc-icon {
  color: gray;
}

#status0 {
  background-color: #ffe0db;
  color: #fc563a;
}

#status2 {
  background-color: #d5f4fc;
  color: #07b8de;
}

#status3 {
  background-color: #fad0a5;
  color: #f26f2e;
}

#status4 {
  background-color: #fff3d8;
  color: #f59920;
}

.colorList {
  border-color: #bdbdbd;
  border: 1px;
  border-style: solid;
  border-radius: 3px;
  padding: 2px;
}

.location-nav {
  margin: 0 0 0 50px;
  border: #bdbdbd 1px solid;
  border-radius: 3px;
}

.location-link {
  border-radius: 3px;
}

.colorInfo {
  margin: 0 50px 0 0;
}

.cleanGif {
  height: 150px;
}
.infoItem {
  border-left: 2px gray solid;
  border-right: 2px gray solid;
  text-align: center;
  padding: 10px;
}
.infoItem h5 {
  height: 80px;
}
.list-group-item {
  margin: 0;
}
.qrCode {
  border-color: #bdbdbd;
  border: 1px;
  border-style: solid;
  border-radius: 5px;
  padding: 3px;
  background-color: #e6fbe1;
  color: #51e53e;
}
.infoModalBody{
  border-left: 2px gray solid;
  border-right: 2px gray solid;
  padding: 0;
}
.refreshButton{
  margin-right: 10px;
}
</style>